import React from "react";
import { sanitizeAndParseStringToHtml } from "../../shared/functions";
import Typography from "@civicplus/preamble-ui/lib/Typography";

interface HtmlDescriptionProps {
    description?: string;
}

export const HtmlDescription: React.FC<HtmlDescriptionProps> = ({ description }) => {
    return <Typography sx={{ wordBreak: "break-all" }}>{sanitizeAndParseStringToHtml(description)}</Typography>;
};
