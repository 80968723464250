import { makeStyles, PreambleTheme } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";

interface useStylesProps {
    hideNavigation: boolean;
    backgroundColor?: string;
}

export const useStyles = makeStyles<PreambleTheme, Partial<useStylesProps>>((theme) => ({
    navRail: {
        width: "120px !important",
        zIndex: 4,
        [theme.breakpoints.down("md")]: {
            width: "100% !important",
            height: "100px !important",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: "auto"
        },
        "&::-webkit-scrollbar": {
            width: 7,
            height: 10
        },
        "&::-webkit-scrollbar-thumb": {
            background: theme.palette.grey[400],
            borderRadius: 25
        },
        "&::-webkit-scrollbar-track": {
            background: theme.palette.background.default
        }
    },
    navRailEmbed: {
        padding: "0 !important",
        height: "800px !important",
        maxHeight: "70vh",
        [theme.breakpoints.down("md")]: {
            height: "100px !important"
        }
    },
    page: {
        "& .prmbl-Inner-contentWrap": {
            left: 0,
            bottom: 100,
            [theme.breakpoints.up("md")]: {
                left: 120,
                bottom: 0
            }
        },
        "& .prmbl-pageInner": {
            [theme.breakpoints.down("md")]: {
                flexDirection: "row"
            }
        },
        "& .prmbl-topbar": {
            overflow: "auto"
        },
        "& .prmbl-topbar-right": {
            [theme.breakpoints.down("md")]: {
                paddingLeft: theme.spacing(0.5)
            }
        }
    },
    pageHideNav: {
        [theme.breakpoints.up("sm")]: {
            left: "0px !important"
        }
    },
    pageEmbed: ({ backgroundColor }) => ({
        background: backgroundColor ?? theme.palette.background.paper,
        maxHeight: "70vh",
        height: "800px",
        position: "relative",
        "& .prmbl-Inner-contentWrap > main": {
            margin: theme.spacing(0, 5)
        }
    }),
    searchContainer: {
        minWidth: "auto",
        [theme.breakpoints.up("md")]: {
            minWidth: "25%"
        }
    },
    navIcon: {
        fill: "currentColor",
        width: "1em",
        height: "1em",
        display: "inline-block",
        fontSize: "1.5rem",
        transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        flexShrink: 0,
        userSelect: "none"
    },
    hideNavOption: {
        display: "none"
    }
}));
