import React, { ChangeEvent } from "react";
import Alert from "@civicplus/preamble-ui/lib/Alert";
import ApiService from "../../../services/apiService";
import ButtonNavigation from "./ButtonNavigation";
import Checkbox from "@civicplus/preamble-ui/lib/Checkbox";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import LanguageItems from "../Language/LanguageItems";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import PhoneNumberListItem from "../PhoneNumber/PhoneNumberListItem";
import PhysicalAddressListItem from "../PhysicalAddress/PhysicalAddressListItem";
import { DataType, StepProps } from "..";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { Organization } from "../../../types/Organization";
import { useAuth } from "../../../providers/AuthProvider";
import { UserPreferences } from "../../../types/Account";

const useStyles = makeStyles((theme) => ({
    main: {
        margin: theme.spacing(2, 0)
    }
}));

type ReviewStepProps = {
    onSubmit: (signupData: DataType) => Promise<boolean>;
    organization: Organization;
    showDefaultSubscriptionCheck: boolean;
} & StepProps;

const ReviewStep: React.FC<ReviewStepProps> = (props) => {
    const { previousStep, nextStep, data, allStepsCompleted, onSubmit, organization, showDefaultSubscriptionCheck } =
        props;
    const auth = useAuth();

    if (!nextStep) throw new Error("nextStep is required");
    if (!previousStep) throw new Error("previousStep is required");
    if (!data) throw new Error("data is required");

    const classes = useStyles();
    const [subscribeToDefault, setSubscribeToDefault] = React.useState(true);
    const { phoneNumbers, physicalAddress, language } = data || {
        phoneNumbers: [],
        isPhoneVerified: false,
        physicalAddresses: undefined,
        language: {}
    };

    const handleSubmit = async () => {
        const userPreferences: UserPreferences = {
            spokenLanguage: language?.spoken?.value,
            writtenLanguage: language?.written?.value
        };

        const [, submitResponse] = await Promise.all([
            ApiService.put({
                url: "user/account/language-timezone",
                authUser: auth.user,
                requestInit: {
                    headers: {
                        "Content-Type": "application/json"
                    },

                    body: JSON.stringify({
                        writtenLanguage: userPreferences.writtenLanguage,
                        spokenLanguage: userPreferences.spokenLanguage
                    })
                }
            }),
            onSubmit({
                phoneNumbers,
                physicalAddress,
                language,
                subscribeToDefault
            })
        ]);
        return submitResponse;
    };

    return (
        <>
            <Typography variant="h5">Review Your Information</Typography>

            <Typography variant="subtitle1">
                Confirm all of your information is correct, if you would like to make any changes, click on the steps
                above.
            </Typography>

            <div className={classes.main}>
                <Grid container={true} spacing={2}>
                    {allStepsCompleted !== true ? (
                        <Grid xs={12}>
                            <Alert id="message-missing-fields" severity="warning" title="Warning">
                                Seems like some of the required fields haven&apos;t been completed. Please ensure all
                                steps are completed before submitting the form.
                            </Alert>
                        </Grid>
                    ) : (
                        <></>
                    )}

                    {phoneNumbers.map((phoneNumber) => (
                        <Grid key={`${phoneNumber.id}-parent`} xs={12}>
                            <PhoneNumberListItem phoneNumber={phoneNumber} />
                        </Grid>
                    ))}

                    {physicalAddress !== undefined ? (
                        <Grid key={`${physicalAddress.id}-parent`} xs={12}>
                            <PhysicalAddressListItem {...physicalAddress} />
                        </Grid>
                    ) : (
                        <></>
                    )}

                    <Grid xs={12}>
                        <LanguageItems spoken={language?.spoken} written={language?.written} disabled={true} />
                    </Grid>

                    {showDefaultSubscriptionCheck && (
                        <Grid xs={12}>
                            <Checkbox
                                id="default-groups-checkbox"
                                color="primary"
                                label={`I would like to subscribe to ${
                                    organization?.friendlyName ?? organization?.name
                                }’s default notifications and alerts.`}
                                checked={subscribeToDefault}
                                onChange={(_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                    setSubscribeToDefault(checked);
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </div>

            <ButtonNavigation
                previousStep={previousStep}
                submit={handleSubmit}
                allStepsCompleted={allStepsCompleted}
                previousAriaLabel="Language Selection"
            />
        </>
    );
};

export default ReviewStep;
