import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AccessibilityMessage from "../../AccessibilityMessage";
import ApiService from "../../../services/apiService";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { shallow } from "zustand/shallow";
import EventsList from "../../Events/EventsList";
import { mdiBank } from "@mdi/js";
import { NavigationOption, Organization, PagedResult } from "../../../types/Organization";
import { useLinks } from "../../../stores/linksStore";
import { useFavorites } from "../../../shared/useFavorites";
import { DocumentType, SearchDocument } from "../../../types/SearchDocument";
import { User as AuthUser } from "oidc-client-ts";
import useStyles from "../styles";
import DashboardZone from "../DashboardZone";
import Button from "@civicplus/preamble-ui/lib/Button";
import { useSnackbar } from "notistack";

interface EventsDashboardProps {
    /**
     * The current user session.
     */
    authUser: AuthUser | null | undefined;
    /**
     * The current organization
     */
    organization?: Organization;
}

const EventsDashboard: React.FC<EventsDashboardProps> = ({ authUser, organization }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [allEvents, setAllEvents] = useState<PagedResult<SearchDocument>>({
        items: [],
        count: 0
    });

    const [{ eventsFavoriteMap }] = useFavorites();

    const { fetchSpecificFavorites, favoriteEvents, loadedAllFavorites } = useLinks(
        (state) => ({
            fetchSpecificFavorites: state.fetchSpecificFavorites,
            favoriteEvents: state.favoriteEvents,
            loadedAllFavorites: state.loadedAllFavorites
        }),
        shallow
    );

    useEffect(() => {
        if (!authUser) {
            return;
        }

        const getEventFavorites = async () => {
            await fetchSpecificFavorites(authUser, DocumentType.Event, enqueueSnackbar);
        };

        if (authUser && !loadedAllFavorites?.events) {
            getEventFavorites();
        }
    }, [authUser, fetchSpecificFavorites, loadedAllFavorites, enqueueSnackbar]);

    useEffect(() => {
        async function fetchEvents() {
            let top = 3;
            if (authUser) {
                top = 1000;
            }
            const fetchedEvents: PagedResult<SearchDocument> = await ApiService.get({
                url: `${
                    organization?.name
                }/search?$top=${top}&$orderby=isFeatured desc,startDate asc&documentType=Event&start=${new Date().toISOString()}`,
                cache: false,
                authUser: authUser
            });

            setAllEvents(fetchedEvents);
            setIsLoading(false);
        }
        fetchEvents();
    }, [authUser, organization?.name]);

    const displayedEvents = useMemo(() => {
        if (authUser && loadedAllFavorites?.events && favoriteEvents && favoriteEvents.length > 0) {
            return allEvents.items.filter((x) => eventsFavoriteMap.get(x.id) !== undefined);
        }

        return allEvents.items;
    }, [allEvents, authUser, loadedAllFavorites, eventsFavoriteMap, favoriteEvents]);

    const hasNoEvents = allEvents.items.length === 0;
    const hasNoFavorites =
        authUser &&
        (favoriteEvents?.length === 0 || (favoriteEvents && favoriteEvents.length > 0 && displayedEvents.length === 0));

    const zoneContent: React.ReactElement = useMemo(() => {
        if (hasNoEvents) {
            return (
                <Typography className={classes.emptyMessage}>
                    At present, <b>{organization?.name || "this organization"}</b> does not have any available events.
                </Typography>
            );
        } else if (hasNoFavorites) {
            return (
                <div className={classes.emptyZone}>
                    <Typography component="h3" variant="h5">
                        You have not saved any Events
                    </Typography>

                    <Typography>
                        <Link onClick={() => navigate(`/${organization?.name}/meetings`)} underline="always">
                            View Meetings Lists
                        </Link>{" "}
                        and use the bookmark action on any Event to
                        <br /> save to your Dashboard for future reference.
                    </Typography>
                </div>
            );
        }
        return <EventsList events={displayedEvents} />;
    }, [
        hasNoEvents,
        hasNoFavorites,
        classes.emptyMessage,
        classes.emptyZone,
        displayedEvents,
        organization?.name,
        navigate
    ]);

    return (
        <DashboardZone
            icon={mdiBank}
            title="Meetings"
            buttons={[
                <Button
                    key="view-all-meetings"
                    id="view-all-events-btn"
                    color="default"
                    onClick={() => navigate(`/${organization?.name}/meetings`)}
                >
                    View all Events
                </Button>
            ]}
            navigationType={NavigationOption.Meetings}
        >
            <section id="events-dashboard-list">
                {isLoading ? <Loader verticallyCenter={true} /> : <> {zoneContent} </>}
                <AccessibilityMessage message={isLoading ? "Loading events" : "Dashboard events loaded"} />
            </section>
        </DashboardZone>
    );
};

export default EventsDashboard;
