import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";

const useStyles = makeStyles((theme) => ({
    flex: {
        display: "flex"
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column"
        }
    },
    title: {
        flex: "1 1 auto",
        marginRight: theme.spacing(2)
    },
    toggles: {
        display: "flex",
        gap: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            justifyContent: "center"
        }
    },
    toggle: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            justifyContent: "center"
        }
    },
    alignEnd: {
        justifyContent: "flex-end"
    },
    fullWidth: {
        [theme.breakpoints.down("md")]: {
            flex: "1"
        }
    },
    filterWithText: {
        [theme.breakpoints.down("md")]: {
            flexWrap: "wrap"
        }
    },
    chipsContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: 5,
        margin: theme.spacing(1, 0, 0),
        overflowX: "auto",
        [theme.breakpoints.down("md")]: {
            justifyContent: "center"
        }
    },
    externalChip: {
        "& svg": {
            marginLeft: theme.spacing(1) + "!important"
        }
    },
    featured: {
        "&:after": {
            content: '""',
            position: "absolute",
            left: 0,
            top: 0,
            width: 5,
            height: "100%",
            backgroundColor: theme.palette.primary.main
        }
    },
    item: {
        position: "relative"
    },
    activeFilter: {
        marginTop: theme.spacing(1),
        "& label": {
            fontWeight: theme.typography.fontWeightBold as number
        }
    },
    tabRoot: {
        "& .MuiTab-root": {
            minWidth: 140,
            [theme.breakpoints.down("sm")]: {
                minWidth: 120,
            }
        }
    },
}));

export default useStyles;
