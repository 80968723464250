import React, { useState, useCallback, useMemo, useEffect } from "react";
import AlertsSubscriptionDialog, { DataType } from "../../../../components/AlertsSubscriptionDialog";
import AlertTabList from "../../../../components/AlertList/AlertTabList";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonDropDown from "@civicplus/preamble-ui/lib/ButtonDropDown";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import FilterButton from "../../../../components/FilterButton/FilterButton";
import languages from "../../../../shared/languages";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import useStyles from "../styles";
import { DocumentType } from "../../../../types/SearchDocument";
import { NotificationsService, SubscriptionAction } from "../../../../services/notificationsService";
import { SearchInput } from "@civicplus/preamble-ui/lib/SearchInput";
import { setProfileInfo, validateKonexusInfo } from "../../../../shared/alertFunctions";
import { SubscriptionList, SubscriptionType } from "../../../../types/Subscription";
import { SubscriptionProfileInformation } from "../../../../types/Account";
import { useAuth } from "../../../../providers/AuthProvider";
import { useEmbedStore } from "../../../../stores/embedStore";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NotificationStatus, useOrganization } from "../../../../stores/organizationStore";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { useApplicationPhoneNumbers } from "../../../../hooks/useApplicationPhoneNumbers";
import { useSnackbar } from "notistack";
import ErrorPage from "@civicplus/preamble-ui/lib/ErrorPage";
import { styled } from "@mui/material/styles";

const ErrorMessage = styled(ErrorPage)(({ theme }) => ({
    margin: theme.spacing(4, 0, 2)
}));

export interface AlertsTabProps {
    onShowContactDialog: () => void;
}

export const AlertsTab: React.FC<AlertsTabProps> = ({ onShowContactDialog }) => {
    const classes = useStyles();
    const auth = useAuth();
    const authUser = auth.user;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { enqueueSnackbar } = useSnackbar();
    const [, userAccountInfo, reloadAccountInfo] = useAccountInfo();
    const [, phoneNumbers] = useApplicationPhoneNumbers();
    const isEmbed = useEmbedStore((state) => state.isInitialized);
    const notificationService = useMemo(() => new NotificationsService(), []);
    const [alerts, setAlerts] = useState<SubscriptionList[]>();
    const [showSubscriptionDialog, setShowSubscriptionDialog] = React.useState(false);
    const [isUnsubscribing, setIsUnsubscribing] = useState(false);
    const [subscribeToAllPending, setSubscribeToAllPending] = useState(false);
    const [searchText, setSearchText] = useState<string>(() => searchParams.get("keyword") ?? "");
    const [
        setOrgSubscriptionsList,
        setFetchNotificationsStatus,
        organization,
        incrementSubsAndUnsubs,
        fetchNotificationsStatus
    ] = useOrganization((state) => [
        state.setOrgSubscriptionsList,
        state.setFetchNotificationsStatus,
        state.organization,
        state.incrementSubsAndUnsubs,
        state.fetchNotificationsStatus
    ]);

    const fetchSubscriptions = useCallback(
        async (controller?: AbortController) => {
            setFetchNotificationsStatus(NotificationStatus.Loading);

            try {
                const subscriptions = await notificationService.getSubscriptions({
                    orgName: organization?.name,
                    authUser: auth.user,
                    documentType: DocumentType.AlertList,
                    controller
                });
                if (controller && controller.signal.aborted) return;
                setOrgSubscriptionsList(subscriptions);
                setAlerts(subscriptions);
                setFetchNotificationsStatus(NotificationStatus.Complete);
            } catch (error) {
                if (controller?.signal.aborted) return;
                console.error(error);
                const message = (error as Error)?.message;
                enqueueSnackbar(message ?? "An error occurred while fetching subscriptions. Please try again.", {
                    variant: "error",
                    persist: true
                });
                setFetchNotificationsStatus(NotificationStatus.Error);
            }
        },
        [
            auth.user,
            enqueueSnackbar,
            notificationService,
            organization?.name,
            setFetchNotificationsStatus,
            setOrgSubscriptionsList
        ]
    );

    useEffect(() => {
        const controller = new AbortController();
        fetchSubscriptions(controller);
        return () => {
            controller.abort();
        };
    }, [fetchSubscriptions]);

    const handleSubscriptionUpdated = (subscription: SubscriptionList) => {
        setAlerts((prev) => {
            if (!prev) return prev;
            return prev.map((item) => {
                if (item.aggregateId === subscription.aggregateId) return subscription;
                else return item;
            });
        });
    };

    const handleClose = () => {
        setShowSubscriptionDialog(false);
    };

    const handleSearch = (searchQuery: string) => {
        if (!isEmbed) {
            navigate(`?tab=alerts&keyword=${searchQuery}`, { replace: true });
        }
        setSearchText(searchQuery);
    };

    const alertsFiltered = useMemo(() => {
        return alerts
            ?.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()))
            .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : 0));
    }, [alerts, searchText]);

    const handleUnsubscribeAll = useCallback(async () => {
        if (organization && authUser) {
            try {
                const subscriptionType = SubscriptionType.None;
                const documentType = DocumentType.AlertList;

                setIsUnsubscribing(true);

                const response = await notificationService.unsubscribeFromAllLists({
                    organization,
                    authUser,
                    subscriptionType,
                    documentType
                });

                if (response.ok) {
                    incrementSubsAndUnsubs();
                    await fetchSubscriptions();
                    enqueueSnackbar("Unsubscribed successfully from all lists.", {
                        variant: "success"
                    });
                } else {
                    enqueueSnackbar(response.error, {
                        variant: "error",
                        persist: true
                    });
                }
            } catch {
                const errorMessage = "Error while trying to unsubscribe user from all lists.";

                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    persist: true
                });
            } finally {
                setIsUnsubscribing(false);
            }
        }
    }, [organization, authUser, notificationService, incrementSubsAndUnsubs, fetchSubscriptions, enqueueSnackbar]);

    const handleSubscribeAll = () => {
        if (!organization || !alerts || alerts.length === 0) {
            return;
        }

        const applicationId = alerts[0].applicationId;

        const { isValid } = validateKonexusInfo(userAccountInfo, phoneNumbers, applicationId);

        if (!isValid) {
            setShowSubscriptionDialog(true);
            return;
        }

        handleSubscribeAllWithSignUpData(null);
    };

    const handleSubscribeAllWithSignUpData = useCallback(
        async (signUpData: DataType | null) => {
            if (!organization || !auth.user) {
                return false;
            }

            try {
                setSubscribeToAllPending(true);
                const profileInfo: SubscriptionProfileInformation | null = setProfileInfo(signUpData);

                const response = await notificationService.subscribeToAllLists({
                    authUser: auth.user,
                    documentType: DocumentType.AlertList,
                    organization,
                    profileInfo
                } as SubscriptionAction);

                if (response.success) {
                    incrementSubsAndUnsubs();
                    await fetchSubscriptions();
                    setShowSubscriptionDialog(false);
                    enqueueSnackbar("Successfully subscribed to all lists.", {
                        variant: "success"
                    });
                } else {
                    throw new Error(response.error);
                }
                return response.success;
            } catch (error) {
                const message = (error as Error)?.message;
                enqueueSnackbar(message ?? "An error occurred while subscribing to all lists. Please try again.", {
                    variant: "error",
                    persist: true
                });
            } finally {
                setSubscribeToAllPending(false);
            }
            return false;
        },
        [auth.user, enqueueSnackbar, fetchSubscriptions, incrementSubsAndUnsubs, notificationService, organization]
    );

    if (!organization) {
        return null;
    }

    return (
        <Grid container={true} spacing={2} justifyContent="flex-end">
            <Grid>
                {auth.user && (
                    <ButtonGroup layout="right">
                        <Button id="manage-contact-btn" onClick={onShowContactDialog}>
                            Manage contact information
                        </Button>

                        <ButtonDropDown
                            key="btn-subscribe-alert-subscriptions"
                            color="primary"
                            options={[
                                {
                                    children: "Subscribe to all",
                                    onClick: () => handleSubscribeAll(),
                                    isLoading: subscribeToAllPending,
                                    "aria-label": "Subscribe to all alerts notifications"
                                }
                            ]}
                        />

                        <ButtonDropDown
                            key="btn-unsubscribe-alert-subscriptions"
                            color="primary"
                            options={[
                                {
                                    children: "Unsubscribe from all",
                                    onClick: () => handleUnsubscribeAll(),
                                    isLoading: isUnsubscribing,
                                    "aria-label": "Unsubscribe from all alerts notifications"
                                }
                            ]}
                        />
                    </ButtonGroup>
                )}
            </Grid>

            <Grid>
                <Grid
                    container={true}
                    spacing={2}
                    justifyContent="flex-end"
                    alignItems="center"
                    wrap="nowrap"
                    className={classes.filterWithText}
                >
                    <Grid xs={12} md="auto">
                        <Grid container={true} spacing={2} justifyContent="flex-end" alignItems="center" wrap="nowrap">
                            <Grid className={classes.fullWidth}>
                                <SearchInput
                                    id="alert-search-bar"
                                    value={searchText ?? ""}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        handleSearch(e.target.value);
                                    }}
                                    searchOnChange={true}
                                    onSearch={handleSearch}
                                    fullWidth={true}
                                    margin={false}
                                    style={{ minWidth: 220 }}
                                />
                            </Grid>

                            <Grid>
                                <FilterButton id="alert-filter" onFilterReset={() => {}}>
                                    {/* todo: define which filter do we want to have here */}
                                    <></>
                                </FilterButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid xs={12}>
                {!alertsFiltered && fetchNotificationsStatus === NotificationStatus.Error ? (
                    <ErrorMessage
                        title="We couldn't retrieve your alerts"
                        linkDescription="Try again"
                        onClick={() => fetchSubscriptions()}
                        severity="error"
                    >
                        An error occurred while retrieving your alerts. Please try again.
                    </ErrorMessage>
                ) : (
                    <AlertTabList
                        alerts={alertsFiltered}
                        handleSubscriptionUpdate={handleSubscriptionUpdated}
                        organization={organization}
                        searchText={searchText}
                    />
                )}
            </Grid>

            {alerts && alerts.length > 0 && showSubscriptionDialog && organization && (
                <AlertsSubscriptionDialog
                    applicationId={alerts[0].applicationId}
                    documentType={DocumentType.AlertWeather}
                    languages={languages}
                    onClose={handleClose}
                    onSubmit={handleSubscribeAllWithSignUpData}
                    open={showSubscriptionDialog}
                    organization={organization}
                    phoneNumbers={phoneNumbers[alerts[0].applicationId]}
                    reloadAccountDetails={reloadAccountInfo}
                    showDefaultSubscriptionCheck={false}
                    userAccountDetails={userAccountInfo}
                />
            )}
        </Grid>
    );
};

export default AlertsTab;
