import React, { useState } from "react";
import ApiService from "../../../services/apiService";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import Checkbox from "@civicplus/preamble-ui/lib/Checkbox";
import enhanceWithValidation, { geoLocationRequireValidation } from "@civicplus/preamble-ui/lib/Validations";
import Geolocation from "@civicplus/preamble-ui/lib/Geolocation";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import { AddressOrLocation, USstatePattern, USzipCodePattern, emptyAddress } from "./AddressUtils";
import { isNullOrWhiteSpace } from "@civicplus/preamble-ui/lib/Utilities/StringHelper";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { softMaxLengthValidation } from "../../../shared/customValidation";
import { useAuth } from "../../../providers/AuthProvider";
import { useConfig } from "../../../providers/ConfigProvider";
import { PhysicalAddress } from "./PhysicalAddressList";
import { useSnackbar } from "notistack";

const EnhancedTextInput = enhanceWithValidation(TextInput);
const EnhancedGeolocation = enhanceWithValidation(Geolocation);

export interface InvalidFields {
    city: boolean;
    state: boolean;
    zip: boolean;
}

const useStyles = makeStyles((theme) => ({
    geoLocation: {
        backgroundColor: theme.palette.background.paper,

        "&:hover": {
            borderColor: theme.palette.primary.main
        }
    }
}));

interface PhysicalAddressFormProps {
    finishedAddAddressAction: (loading: boolean, newAddress?: PhysicalAddress) => void;
}

const PhysicalAddressForm: React.FC<PhysicalAddressFormProps> = (props) => {
    const { finishedAddAddressAction } = props;

    const classes = useStyles();
    const config = useConfig();
    const auth = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    const [addressValues, setAddressValues] = useState<AddressOrLocation>(emptyAddress);
    const [enableSave, setEnableSave] = useState(false);
    const [hasInvalidFields, setHasInvalidFields] = useState(false);

    const allValues = {
        address1: addressValues.address1,
        address2: addressValues.address2,
        city: addressValues.city,
        state: addressValues.state,
        zip: addressValues.zipCode,
        country: addressValues.country
    };

    const geoProps = {
        className: classes.geoLocation,
        id: "geolocation-with-map",
        editor: "Map",
        googleMapsKey: config?.googleMapsKey,
        validateFields: {
            validateAddress1: false,
            validateAddress2: false,
            validateCity: true,
            validateState: true,
            validateZipCode: true
        },
        capitalizeState: true,
        statePattern: addressValues.country?.value === "US" ? USstatePattern : undefined,
        statePatternMessage: "This value does not conform to a U.S. state.", // switch case for international message?
        zipCodePattern: addressValues.country?.value === "US" ? USzipCodePattern : undefined,
        zipCodePatternMessage: "This value does not match a valid U.S. zip code.",
        error: false,
        onClear: () => true,
        onValidation: (invalidFields: InvalidFields) => {
            if (isNullOrWhiteSpace(addressValues.city)) {
                invalidFields.city = true;
            }
            if (invalidFields.city || invalidFields.state || invalidFields.zip) {
                setHasInvalidFields(true);

                setEnableSave(false);
            } else {
                setHasInvalidFields(false);

                if (addressValues.city && addressValues.state && addressValues.zipCode) {
                    setEnableSave(true);
                }
            }
        },
        onChange: ({ address1, address2, city, state, zip, latitude, longitude, searchedAddress, country }: any) => {
            setAddressValues({
                ...addressValues,
                address1: address1,
                address2: address2,
                city: city,
                state: state,
                zipCode: zip,
                latitude: latitude,
                longitude: longitude,
                fullAddress: addressValues.fullAddress ? addressValues.fullAddress : searchedAddress,
                country: country
            });
        },
        fields: {
            showLabel: false,
            showAddress1: true,
            showAddress2: true,
            showCity: true,
            showState: true,
            showZipCode: true,
            showCountry: true
        },
        value: allValues
    };

    const onCustomLabelChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e && e.currentTarget) {
            setAddressValues({ ...addressValues, label: e.currentTarget?.value });
        }
    };

    const onShippingAddressChanged = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setAddressValues({ ...addressValues, isShippingAddress: checked });
    };

    const onSaveAddress = async () => {
        if (!addressValues.city || !addressValues.state || !addressValues.zipCode) {
            enqueueSnackbar("City, State and Zip Code must be valid to continue.", {
                variant: "error",
                persist: true
            });
            return;
        }

        if (hasInvalidFields) {
            enqueueSnackbar("All fields must be valid to continue.", {
                variant: "error",
                persist: true
            });
            return;
        }
        finishedAddAddressAction(true, undefined);

        setEnableSave(false);

        const postBody = {
            address1: addressValues.address1,
            address2: addressValues.address2,
            city: addressValues.city,
            fullAddress: addressValues.fullAddress,
            isShippingAddress: addressValues.isShippingAddress,
            label: addressValues.label,
            latitude: addressValues.latitude,
            longitude: addressValues.longitude,
            addressType: "Building",
            country: addressValues.country?.value,
            countryLabel: addressValues.country?.label,
            governingDistrict: addressValues.state,
            localMunicipality: "",
            postalArea: addressValues.zipCode
        };

        const url = `user/account/physicalAddress`;

        await ApiService.post({
            url,
            authUser: auth.user,
            requestInit: {
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(postBody)
            }
        })
            .then((response) => {
                enqueueSnackbar("Address has been saved.", {
                    variant: "success"
                });

                if (response) {
                    setAddressValues((prevAddress) => ({ ...prevAddress, id: response.id }));
                    finishedAddAddressAction(false, response);
                }

                return true;
            })
            .catch(() => {
                enqueueSnackbar("Failed to save a new address", {
                    variant: "error",
                    persist: true
                });
            });
    };

    return (
        <>
            <Grid container={true}>
                <Grid xs={12}>
                    <EnhancedTextInput
                        id="addresslabel"
                        label="Address Label"
                        value={addressValues.label}
                        onChange={onCustomLabelChanged}
                        fullWidth={true}
                        softMaxLength={30}
                        validations={[softMaxLengthValidation]}
                    />
                </Grid>

                <EnhancedGeolocation validations={[geoLocationRequireValidation]} {...geoProps} />

                <Grid xs={12}>
                    <Grid container={true} justifyContent="flex-end">
                        <Grid>
                            <ButtonGroup id="saveAndCancel">
                                <Button color="primary" onClick={() => onSaveAddress()} disabled={!enableSave}>
                                    Save Changes
                                </Button>

                                <Button
                                    data-testid="cancel-add-address"
                                    onClick={() => {
                                        return finishedAddAddressAction(false, undefined);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container={true}>
                <Grid xs={12} md="auto">
                    <Checkbox
                        id="isShipping"
                        onChange={onShippingAddressChanged}
                        checked={addressValues.isShippingAddress}
                        size="small"
                        label={`Shipping Address is the same as ${
                            addressValues.label || emptyAddress.label || "Primary Address or Location"
                        }`}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default PhysicalAddressForm;
