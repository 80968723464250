import ErrorVariant from "./ErrorVariant";
import SuccessVariant from "./SuccessVariant";
import WarningVariant from "./WarningVariant";

export const SnackbarProviderProps = {
    Components: {
        success: SuccessVariant,
        error: ErrorVariant,
        warning: WarningVariant
    },
    preventDuplicate: true
};

export default SnackbarProviderProps;
