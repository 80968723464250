import { createWithEqualityFn } from "zustand/traditional";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { User } from "oidc-client-ts";
import { SnackbarMessage, OptionsObject, SnackbarKey } from "notistack";
import ApiService from "../services/apiService";
import { Link, PublicLink } from "../types/Link";
import { DocumentType } from "../types/SearchDocument";

interface LoadedAllFavorites {
    links: boolean;
    forms: boolean;
    subscriptionsList: boolean;
    events: boolean;
}

export interface LinkState {
    links: Link[];
    publicLinks?: PublicLink[];
    fetchLinks: (organizationName: string, resetCache?: boolean) => Promise<Link[]>;
    fetchLinksStatus: "Pending" | "Loading" | "Complete";
    favorites: Link[];
    favoriteLinks: Link[];
    favoriteForms: Link[];
    favoriteSubscriptionLists: Link[];
    favoriteEvents: Link[];
    loadedFavorites: boolean;
    loadedAllFavorites: LoadedAllFavorites;
    organizationName: string;
    linkToFavorite: Link | undefined;
    setLinkToFavorite: (link: Link | undefined) => void;
    getLinksFromSection: (organizationName: string | undefined, sectionName: string) => Link[] | undefined;
    fetchFavorites: (
        user: User,
        enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey
    ) => Promise<Link[]>;
    fetchSpecificFavorites: (
        user: User,
        documentType: DocumentType,
        enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey
    ) => Promise<void>;
    fetchPublicLinks: (user: User) => Promise<void>;
    setFavoriteLink: (
        aggregateId: string,
        link: Link,
        isFavorite: boolean,
        user: User,
        enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey
    ) => void;
    clear: () => void;
    clearFavorites: () => void;
    clearPublicLinks: () => void;
}

export const useLinks = createWithEqualityFn<LinkState>()(
    persist(
        devtools(
            (set, get) => ({
                links: [],
                publicLinks: undefined,
                fetchLinksStatus: "Pending",
                favorites: [],
                favoriteLinks: [],
                favoriteForms: [],
                favoriteSubscriptionLists: [],
                favoriteEvents: [],
                loadedFavorites: false,
                loadedAllFavorites: { links: false, forms: false, subscriptionsList: false, events: false },
                organizationName: "",
                linkToFavorite: undefined,
                setLinkToFavorite: (link) => set({ linkToFavorite: link }),

                fetchLinks: async (organizationName: string, resetCache?: boolean) => {
                    if (get().fetchLinksStatus === "Pending" || get().organizationName !== organizationName) {
                        set({ fetchLinksStatus: "Loading" });

                        const fetchedLinks: Link[] = await ApiService.get({
                            url: `${organizationName}/links`,
                            resetCache: resetCache === undefined ? false : resetCache
                        });

                        set({ links: fetchedLinks, organizationName: organizationName, fetchLinksStatus: "Complete" });
                    }

                    return get().links;
                },

                fetchFavorites: async (
                    user: User,
                    enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey
                ) => {
                    if (!get().loadedFavorites) {
                        const fetchedFavorites: Link[] = await ApiService.get({
                            url: "favorites",
                            authUser: user,
                            cache: false,
                            resetCache: true
                        });
                        set({
                            favorites: fetchedFavorites,
                            loadedFavorites: true
                        });
                    }

                    const state = get();

                    if (state.linkToFavorite) {
                        state.setFavoriteLink(
                            state.linkToFavorite.aggregateId,
                            state.linkToFavorite,
                            true,
                            user,
                            enqueueSnackbar
                        );
                    }

                    return get().favorites;
                },

                fetchSpecificFavorites: async (
                    user: User,
                    documentType: DocumentType,
                    enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey
                ) => {
                    const state = get();

                    if (state.linkToFavorite && state.linkToFavorite.documentType === documentType) {
                        await state.setFavoriteLink(
                            state.linkToFavorite.aggregateId,
                            state.linkToFavorite,
                            true,
                            user,
                            enqueueSnackbar
                        );
                    }

                    const fetchedFavorites: Link[] = await ApiService.get({
                        url: `favorites/${documentType}`,
                        authUser: user,
                        cache: false,
                        resetCache: true
                    });

                    switch (documentType) {
                        case DocumentType.Link:
                            set({
                                favoriteLinks: fetchedFavorites,
                                loadedAllFavorites: { ...state.loadedAllFavorites, links: true }
                            });
                            break;
                        case DocumentType.Form:
                            set({
                                favoriteForms: fetchedFavorites,
                                loadedAllFavorites: { ...state.loadedAllFavorites, forms: true }
                            });
                            break;
                        case DocumentType.SubscriptionList:
                            set({
                                favoriteSubscriptionLists: fetchedFavorites,
                                loadedAllFavorites: { ...state.loadedAllFavorites, subscriptionsList: true }
                            });
                            break;
                        case DocumentType.Event:
                            set({
                                favoriteEvents: fetchedFavorites,
                                loadedAllFavorites: { ...state.loadedAllFavorites, events: true }
                            });
                            break;
                        default:
                            break;
                    }
                },

                fetchPublicLinks: async (user: User) => {
                    const pbLinks: PublicLink[] = await ApiService.get({
                        url: "publicLinks/all",
                        authUser: user,
                        cache: false,
                        resetCache: true
                    });

                    set({ publicLinks: pbLinks });
                },

                getLinksFromSection: (organizationName: string | undefined, sectionName: string) => {
                    if (organizationName !== get().organizationName) {
                        return [];
                    }
                    const links = get().links;
                    return links.filter((link) => link.section.toUpperCase() === sectionName.toUpperCase());
                },

                setFavoriteLink: async (
                    aggregateId: string,
                    link: Link,
                    isFavorite: boolean,
                    user: User,
                    enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey
                ) => {
                    const store = get();
                    let favoriteArray: Link[] = [];

                    switch (link.documentType) {
                        case DocumentType.Event:
                            favoriteArray = store.favoriteEvents;
                            break;
                        case DocumentType.Link:
                            favoriteArray = store.favoriteLinks;
                            break;
                        case DocumentType.Form:
                            favoriteArray = store.favoriteForms;
                            break;
                        case DocumentType.SubscriptionList:
                            favoriteArray = store.favoriteSubscriptionLists;
                            break;
                    }

                    const specificFavorites = new Map(
                        favoriteArray.map((f) => {
                            return [f.aggregateId ?? f.id, f];
                        })
                    );

                    const favorites = new Map(
                        store.favorites.map((f) => {
                            return [f.aggregateId ?? f.id, f];
                        })
                    );

                    const existingFavorite = specificFavorites.get(aggregateId);

                    if (!existingFavorite) {
                        favorites.set(link.aggregateId, link);
                        specificFavorites.set(link.aggregateId, link);

                        await ApiService.post({
                            url: `favorites/${aggregateId}`,
                            requestInit: undefined,
                            authUser: user
                        })
                            .then(() => {
                                enqueueSnackbar(`You have bookmarked "${link.title}"`, {
                                    variant: "success"
                                });

                                return undefined;
                            })
                            .catch(() => {
                                enqueueSnackbar("An error occured while adding the bookmark", {
                                    variant: "error",
                                    persist: true
                                });
                            });
                    }

                    if (existingFavorite && !isFavorite) {
                        await ApiService.delete({
                            url: `favorites/${aggregateId}`,
                            requestInit: undefined,
                            authUser: user
                        })
                            .then(() => {
                                enqueueSnackbar(`You have removed the bookmark from "${existingFavorite.title}"`, {
                                    variant: "success"
                                });

                                favorites.delete(existingFavorite.aggregateId);
                                specificFavorites.delete(existingFavorite.aggregateId);

                                return undefined;
                            })
                            .catch(() => {
                                enqueueSnackbar("An error occured while removing bookmark from link", {
                                    variant: "error",
                                    persist: true
                                });
                            });
                    }

                    if (get().linkToFavorite?.aggregateId === aggregateId) {
                        set({ linkToFavorite: undefined });
                    }

                    set({ favorites: [...favorites.values()] });

                    switch (link.documentType) {
                        case DocumentType.Event:
                            set({ favoriteEvents: [...specificFavorites.values()] });
                            break;
                        case DocumentType.Link:
                            set({ favoriteLinks: [...specificFavorites.values()] });
                            break;
                        case DocumentType.Form:
                            set({ favoriteForms: [...specificFavorites.values()] });
                            break;
                        case DocumentType.SubscriptionList:
                            set({ favoriteSubscriptionLists: [...specificFavorites.values()] });
                            break;
                    }
                },

                clear: () => {
                    set({
                        links: [],
                        publicLinks: undefined,
                        fetchLinksStatus: "Pending",
                        favorites: [],
                        favoriteLinks: [],
                        favoriteForms: [],
                        favoriteSubscriptionLists: [],
                        favoriteEvents: [],
                        loadedFavorites: false,
                        loadedAllFavorites: { links: false, forms: false, subscriptionsList: false, events: false }
                    });
                },

                clearFavorites: () => {
                    set({
                        publicLinks: get().publicLinks,
                        favorites: [],
                        favoriteLinks: [],
                        favoriteForms: [],
                        favoriteSubscriptionLists: [],
                        favoriteEvents: [],
                        loadedFavorites: false,
                        loadedAllFavorites: { links: false, forms: false, subscriptionsList: false, events: false }
                    });
                },

                clearPublicLinks: () => {
                    set({ publicLinks: undefined });
                }
            }),
            {
                name: "linkStore-1"
            }
        ),
        {
            name: "linkStore-1",
            storage: createJSONStorage(() => sessionStorage)
        }
    )
);
