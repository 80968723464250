import React, { useState } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";

export type ButtonNavigationProps = {
    submit?: () => Promise<boolean>;
    previousStep?: () => void;
    nextStep?: () => void;
    allStepsCompleted?: boolean;
    previousAriaLabel?: string;
    nextAriaLabel?: string;
};

const ButtonNavigation: React.FC<ButtonNavigationProps> = (props) => {
    const { submit, nextStep, previousStep, allStepsCompleted, previousAriaLabel, nextAriaLabel } = props;

    const [onSubmitting, setOnSubmitting] = useState(false);
    const onSubmitLocal = async () => {
        setOnSubmitting(true);
        let response = false;

        if (submit) {
            response = await submit();
        }

        setOnSubmitting(false);
        return response;
    };

    return (
        <ButtonGroup layout="right" id="stepNavigation">
            {previousStep && (
                <Button
                    onClick={previousStep}
                    id="alerts-verification-prev-btn"
                    data-testid="alerts-verification-prev-btn"
                    aria-label={`Go back to ${previousAriaLabel}`}
                >
                    Previous
                </Button>
            )}

            {nextStep && (
                <Button
                    onClick={nextStep}
                    id="alerts-verification-next-btn"
                    data-testid="alerts-verification-next-btn"
                    aria-label={`Proceed to ${nextAriaLabel}`}
                >
                    Next
                </Button>
            )}

            {submit && (
                <Button
                    color="primary"
                    id="alerts-verification-save-btn"
                    onClick={onSubmitLocal}
                    disabled={!allStepsCompleted}
                    isLoading={onSubmitting}
                >
                    Save and Complete
                </Button>
            )}
        </ButtonGroup>
    );
};

export default ButtonNavigation;
