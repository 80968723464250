import { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import { PhoneNumber } from "./PhoneNumberUtils";

export const phoneOptions = (existingPhoneNumbers: PhoneNumber[] | undefined): OptionShape[] => {
    if (!existingPhoneNumbers) {
        return []
    };

    return existingPhoneNumbers?.map((phoneNumber: PhoneNumber) => {
        return { value: phoneNumber.id, label: phoneNumber.number };
    });
};

export const formatPhoneValue = (phoneNumber: PhoneNumber | undefined): OptionShape | undefined => {
    if (!phoneNumber) {
        return undefined;
    }

    return { value: phoneNumber.id, label: phoneNumber.number };
};
