import React, { useState, useEffect } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonNavigation from "../Components/ButtonNavigation";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import PhysicalAddressForm from "./PhysicalAddressForm";
import PhysicalAddressList, { PhysicalAddress } from "./PhysicalAddressList";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { maxNumberOfAddresses } from "../../../shared/constants";
import { useConfig } from "../../../providers/ConfigProvider";
import { StepProps } from "..";

interface PhysicalAddressStepProps extends StepProps {
    existingPhysicalAddresses: PhysicalAddress[];
}

const PhysicalAddressStep: React.FC<PhysicalAddressStepProps> = (props) => {
    const { data, previousStep, nextStep, setCompleted, setData, existingPhysicalAddresses } = props;

    if (!nextStep) throw new Error("nextStep is required");
    if (!previousStep) throw new Error("previousStep is required");
    if (!setData) throw new Error("setData is required");
    if (!setCompleted) throw new Error("setCompleted is required");

    const config = useConfig();

    const [isAdding, setIsAdding] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleChangeSelectedPhysicalAddresses = (selectedPhysicalAddress?: PhysicalAddress) => {
        setData({ physicalAddress: selectedPhysicalAddress });
        setCompleted(selectedPhysicalAddress !== undefined);
    };

    const finishedAddAddressAction = (loading: boolean, newAddress?: PhysicalAddress) => {
        setIsLoading(loading);
        setIsAdding(false);

        if (newAddress) {
            existingPhysicalAddresses && existingPhysicalAddresses.push(newAddress);
        }
    };

    useEffect(() => {
        if (!existingPhysicalAddresses) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [existingPhysicalAddresses]);

    return (
        <div>
            <Typography variant="h5">Address/Location</Typography>

            <Typography variant="subtitle1" style={{ margin: "15px 0" }}>
                {(existingPhysicalAddresses.length ?? 0) > 0 ? (
                    <>
                        Please select from your currently provided addresses/locations. Only one location can be
                        selected.<br></br>
                        If you need to manage your contact information, please follow this link to{" "}
                        <Link
                            onClick={() => window.open(`${config.accountServiceBaseUrl}/contact`, "_blank")}
                            key="account-service-address-link"
                            underline="always"
                            style={{ cursor: "pointer" }}
                        >
                            Account Service.
                        </Link>
                    </>
                ) : (
                    <>To proceed, an address is needed. Please add a new one by clicking on the button below.</>
                )}
            </Typography>

            {isLoading ? (
                <Loader verticallyCenter={true} style={{ padding: 50 }} />
            ) : isAdding ? (
                <>
                    <Typography variant="subtitle1" style={{ margin: "15px 0" }}>
                        Type in your address in the Google Maps field below and select from the drop down as the list
                        populates to choose the correct one. If the selector does not find your address, try typing in
                        your city name or zip code. A location must be selected in order to continue.
                    </Typography>

                    <PhysicalAddressForm finishedAddAddressAction={finishedAddAddressAction} />
                </>
            ) : (
                <>
                    <PhysicalAddressList
                        existingPhysicalAddresses={existingPhysicalAddresses}
                        selectedPhysicalAddress={data?.physicalAddress}
                        onChangeSelectedPhysicalAddresses={handleChangeSelectedPhysicalAddresses}
                    />

                    <Button
                        variant="text"
                        id="addPhysicalAddress"
                        data-testid="addPhysicalAddress"
                        disabled={(existingPhysicalAddresses.length ?? 0) >= maxNumberOfAddresses}
                        onClick={() => {
                            setIsAdding(true);
                        }}
                    >
                        + Add New Address
                    </Button>
                </>
            )}

            <ButtonNavigation
                previousStep={previousStep}
                nextStep={nextStep}
                previousAriaLabel="Phone Verification"
                nextAriaLabel="Language Selection"
            />
        </div>
    );
};

export default PhysicalAddressStep;
