import React, { useMemo, useState } from "react";
import AlertsSubscriptionDialog, { DataType } from "../../../../components/AlertsSubscriptionDialog";
import languages from "../../../../shared/languages";
import { DirtyStateDialog } from "../../../../components/DirtyState";
import { NotificationsService } from "../../../../services/notificationsService";
import { ProductType } from "../../../../types/ProductType";
import { PhoneNumberType } from "../../../../components/AlertsSubscriptionDialog/PhoneNumber/PhoneNumberUtils";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { useApplicationPhoneNumbers } from "../../../../hooks/useApplicationPhoneNumbers";
import { useAuth } from "../../../../providers/AuthProvider";
import { useOrganization } from "../../../../stores/organizationStore";
import { useSnackbar } from "notistack";

interface AlertContactDialogProps {
    show: boolean;
    toggleDialogVisibility: (value: boolean) => void;
}

export const AlertsContactDialog: React.FC<AlertContactDialogProps> = ({ show, toggleDialogVisibility }) => {
    const auth = useAuth();
    const notificationService = useMemo(() => new NotificationsService(), []);
    const [organization] = useOrganization((state) => [state.organization]);
    const [, userAccountInfo, reloadAccountInfo] = useAccountInfo();
    const [, phoneNumbers, , reloadPhoneNumbers] = useApplicationPhoneNumbers();
    const { enqueueSnackbar } = useSnackbar();
    const [isDirty, setDirty] = useState(false);
    const [isLeavingWithoutSaving, setIsLeavingWithoutSaving] = useState(false);

    if (!organization) return null;

    const applicationId = organization.applications.find((app) => app.productType === ProductType.CivicReady)?.id || "";

    const handleContactSubmit = async (signUpData: DataType) => {
        toggleDialogVisibility(true);

        const response = await notificationService.updateUserPreferences({
            organization,
            authUser: auth.user,
            cellPhoneId: signUpData.phoneNumbers.find((x) => x.type === PhoneNumberType.CellPhone)?.id,
            cellPhoneSecondaryId: signUpData.phoneNumbers.find((x) => x.type === PhoneNumberType.CellPhoneSecondary)
                ?.id,
            homePhoneId: signUpData.phoneNumbers.find((x) => x.type === PhoneNumberType.HomePhone)?.id,
            workPhoneId: signUpData.phoneNumbers.find((x) => x.type === PhoneNumberType.WorkPhone)?.id,
            physicalAddressId: signUpData.physicalAddress?.id,
            spokenLanguageOption: signUpData.language?.spoken?.value,
            writtenLanguageOption: signUpData.language?.written?.value
        });

        if (response.error) {
            enqueueSnackbar(response.error, {
                variant: "error",
                persist: true
            });
        } else {
            enqueueSnackbar("Your contact preferences have been updated successfully", {
                variant: "success"
            });
        }

        if (!response.error) {
            reloadAccountInfo();
            toggleDialogVisibility(false);
        }

        return true;
    };

    const handleCloseDialog = () => {
        if (isDirty) {
            setIsLeavingWithoutSaving(true);
        } else {
            toggleDialogVisibility(false);
        }
    };

    return (
        <>
            {show && (
                <AlertsSubscriptionDialog
                    open={show}
                    onClose={handleCloseDialog}
                    onSubmit={handleContactSubmit}
                    organization={organization}
                    title="Manage Contact Information"
                    languages={languages}
                    userAccountDetails={userAccountInfo}
                    reloadAccountDetails={reloadAccountInfo}
                    applicationId={applicationId}
                    phoneNumbers={phoneNumbers[applicationId]}
                    showDefaultSubscriptionCheck={false}
                    setIsDirty={setDirty}
                />
            )}

            <DirtyStateDialog
                isOpen={isLeavingWithoutSaving}
                warningText="You have unsaved changes. Are you sure you want to leave?"
                onReturnToContent={() => {
                    setIsLeavingWithoutSaving(false);
                }}
                onDiscardChanges={() => {
                    setIsLeavingWithoutSaving(false);
                    toggleDialogVisibility(false);
                    reloadPhoneNumbers();
                }}
            />
        </>
    );
};

export default AlertsContactDialog;
