import React, { useEffect, useState } from "react";
import ApiService from "../../../services/apiService";
import Autocomplete, { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import FilterButton from "../../../components/FilterButton";
import FormsList from "../../../components/Forms/FormsList";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import HiddenPageAlert from "../../../components/HiddenPageAlert";
import Layout from "@civicplus/preamble-ui/lib/Layout";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import SearchInput from "@civicplus/preamble-ui/lib/SearchInput";
import useStyles from "./styles";
import { Breakpoint } from "@mui/material/styles";
import { DocumentType, SearchDocument } from "../../../types/SearchDocument";
import { FormatTags, getTags } from "../../../components/EditLinkDialog/EditLinkUtilities";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { Organization, PagedResult, NavigationOption } from "../../../types/Organization";
import { OrganizationState, useOrganization } from "../../../stores/organizationStore";
import { TablePagination } from "@civicplus/preamble-ui/lib/TablePagination";
import { toggleDisplayFraseChatbot } from "../../../shared/functions";
import { useAuth } from "../../../providers/AuthProvider";
import { useDebounce } from "use-debounce";
import { useEmbedStore } from "../../../stores/embedStore";
import { useLinks } from "../../../stores/linksStore";
import { HtmlDescription } from "../../../components/Layout/HtmlDescription";
import { useSnackbar } from "notistack";
interface FormsProps {
    org: Organization;
    widthOverride?: Breakpoint;
    queryKeyword: string | null;
    queryTags: OptionShape[];
}

export const FormsAccessWrapper: React.FC = () => {
    const auth = useAuth();
    const isLoadingSession = auth.isLoading;
    const [searchParams] = useSearchParams();
    const [organization] = useOrganization((state: OrganizationState) => [state.organization]);

    const queryKeyword = searchParams.get("keyword") ?? null;
    const allQueryTags = searchParams.getAll("tag") ?? "";
    const queryTags: OptionShape[] = [];

    if (allQueryTags.length > 0) {
        allQueryTags.forEach((tag: string) => {
            if (!queryTags.find((t) => t.value === tag)) {
                queryTags.push({
                    label: tag,
                    value: tag
                });
            }
        });
    }

    if (isLoadingSession) {
        return <Loader verticallyCenter={true} />;
    }

    if (organization) {
        return <Forms org={organization} queryKeyword={queryKeyword} queryTags={queryTags} />;
    }

    return <Loader verticallyCenter={true} />;
};

const getOptionShapeValues = (options: OptionShape[]) => {
    const values: string[] = [];

    options.forEach((x) => {
        if (typeof x.value === "string") {
            values.push(x.value);
        }
    });

    return values;
};

export const Forms: React.FC<FormsProps> = ({ org, queryKeyword, queryTags }) => {
    const [forms, setForms] = useState<PagedResult<SearchDocument>>({
        items: [],
        count: 0
    });
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const isEmbed = useEmbedStore((state) => state.isInitialized);
    const [tags, setTags] = useState<OptionShape[]>([]);
    const [selectedTags, setSelectedTags] = useState<OptionShape[]>(() => queryTags);
    const [currentSearchTerm, setCurrentSearchTerm] = useState<string | null>(() => queryKeyword);
    const { enqueueSnackbar } = useSnackbar();

    const [debouncedSearchTerm] = useDebounce(currentSearchTerm, 300);

    const { fetchSpecificFavorites } = useLinks((state) => ({
        fetchSpecificFavorites: state.fetchSpecificFavorites
    }));

    const auth = useAuth();
    const classes = useStyles();

    useEffect(
        function updateFraseChatbot() {
            toggleDisplayFraseChatbot(org, isEmbed || false);
        },
        [org, isEmbed]
    );

    useEffect(
        function searchForms() {
            let isCancelled = false;
            async function internalSearchForms() {
                const skip = page * rowsPerPage;
                const searchPath = debouncedSearchTerm === null ? "" : debouncedSearchTerm;

                const searchResults: PagedResult<SearchDocument> = await ApiService.get({
                    url: `${
                        org.name
                    }/search/${searchPath}?$top=${rowsPerPage}&$skip=${skip}&$orderby=title asc&$filter=${getOptionShapeValues(
                        selectedTags
                    )}&documentType=Form`,
                    cache: false,
                    authUser: auth.user
                });
                if (!isCancelled) {
                    setForms(searchResults);
                    setIsLoading(false);
                }
            }
            internalSearchForms();

            return () => {
                isCancelled = true;
            };
        },
        [auth.user, debouncedSearchTerm, org.name, page, rowsPerPage, selectedTags]
    );

    useEffect(
        function updateUrlForNewTagsAndKeyword() {
            if (!isEmbed) {
                const url = window.location.href.split("?");

                if (selectedTags.length > 0) {
                    if (currentSearchTerm) {
                        let newUrl = `${url[0]}?keyword=${currentSearchTerm}`;

                        selectedTags.forEach((tag) => {
                            newUrl = newUrl.concat(`&tag=${tag.value}`);
                        });

                        window.history.replaceState({}, "", newUrl);
                    } else {
                        let newUrl = `${url[0]}?`;

                        selectedTags.forEach((tag, index) => {
                            if (index !== 0) newUrl = newUrl.concat("&");
                            newUrl = newUrl.concat(`tag=${tag.value}`);
                        });

                        window.history.replaceState({}, "", newUrl);
                    }
                } else {
                    window.history.replaceState(
                        {},
                        "",
                        currentSearchTerm ? `${url[0]}?keyword=${currentSearchTerm}` : `${url[0]}`
                    );
                }
            }
        },
        [isEmbed, currentSearchTerm, selectedTags]
    );

    useEffect(
        function loadAllTags() {
            const loadTags = async () => {
                const allTags = await getTags(org.name, auth.user, DocumentType.Form);
                const convertedTags = FormatTags(allTags);
                setTags(convertedTags);
            };

            loadTags();
        },
        [auth.user, org.name]
    );

    useEffect(
        function loadUserFavorites() {
            if (!auth.isAuthenticated) {
                return;
            }

            const getFavorites = async () => {
                if (auth.user) {
                    await fetchSpecificFavorites(auth.user, DocumentType.Form, enqueueSnackbar);
                }
            };

            getFavorites();
        },
        [auth.isAuthenticated, auth.user, fetchSpecificFavorites, enqueueSnackbar]
    );

    useEffect(() => {
        setPage(0);
    }, [debouncedSearchTerm, selectedTags]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (searchQuery: string) => {
        if (searchQuery.length === 0) {
            setCurrentSearchTerm(null);
            setPage(0);
            setRowsPerPage(10);
        } else {
            setCurrentSearchTerm(searchQuery);
        }
    };

    const onChangeTag = (selected: OptionShape[] | undefined) => {
        selected ? setSelectedTags(selected) : setSelectedTags([]);
    };

    const handleFilterReset = () => {
        setSelectedTags([]);
    };

    const navigationItem = org.navigation && org.navigation[NavigationOption.Forms];
    return (
        <Layout
            TitlebarProps={{
                id: "forms-titlebar",
                title: navigationItem ? navigationItem.customLabel ?? navigationItem.defaultLabel : "Forms",
                breadcrumbs: [
                    <Link key="home" to={`/${org?.name}`} component={RouterLink}>
                        Home
                    </Link>
                ],
                description: <HtmlDescription description={navigationItem?.additionalProductInformation} />
            }}
        >
            {isLoading ? (
                <Loader verticallyCenter={true} role="alert" aria-live="assertive" aria-label="Loading Forms" />
            ) : (
                <></>
            )}

            <HiddenPageAlert navOption={NavigationOption.Forms} />

            <section aria-busy={isLoading} aria-live="polite">
                {!isLoading ? (
                    <>
                        <Grid container={true} spacing={2} justifyContent="flex-end" alignItems="center">
                            <Grid className={classes.fullWidth}>
                                <SearchInput
                                    id="forms-search-bar"
                                    value={currentSearchTerm ?? ""}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        handleSearch(e.target.value);
                                    }}
                                    searchOnChange={true}
                                    onSearch={handleSearch}
                                    fullWidth={true}
                                    margin={false}
                                />
                            </Grid>

                            <Grid>
                                <FilterButton
                                    id="forms-filter"
                                    activeFilters={selectedTags.length}
                                    onFilterReset={handleFilterReset}
                                >
                                    <Autocomplete
                                        id="tag-filter"
                                        label="Tags"
                                        onChange={onChangeTag}
                                        value={selectedTags}
                                        options={tags}
                                        multiSelect={true}
                                        margin={false}
                                        fullWidth={true}
                                        isInDialog={true}
                                    />
                                </FilterButton>
                            </Grid>
                        </Grid>

                        <FormsList forms={forms.items} orgName={org?.name} />

                        <TablePagination
                            id="forms-pagination"
                            component="div"
                            count={forms.count}
                            page={page}
                            className={classes.pagination}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                ) : (
                    <></>
                )}
            </section>
        </Layout>
    );
};
