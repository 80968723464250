import { ProductType } from "../types/ProductType";

export const maxNumberOfAddresses = 1;

export const publicUriTypes = [
    ProductType.CivicGov,
    ProductType.CivicReady,
    ProductType.CivicRec,
    ProductType.MunicodeNEXT,
    ProductType.NextRequest,
    ProductType.SeeClickFix
];

export const defaultApplicationLabels = {
    [ProductType.CivicClerk]: "Agendas & Meetings",
    [ProductType.CivicGov]: "Permits & Inspections",
    [ProductType.CivicReady]: "Alerts",
    [ProductType.CivicRec]: "Parks & Rec",
    [ProductType.MunicodeNEXT]: "Legislation",
    [ProductType.NextRequest]: "Records Request",
    [ProductType.SeeClickFix]: "Submit a Request"
};

export const ProductTypeLabels = {
    [ProductType.ArchiveSocial]: "Social Media Archiving",
    [ProductType.CivicClerk]: "Meetings Select",
    [ProductType.CivicEngageCentral]: "Web Central",
    [ProductType.CivicEngageEvolve]: "Web Evolve",
    [ProductType.CivicEngageOpen]: "Web Open",
    [ProductType.CivicGov]: "Code Enforcement",
    [ProductType.CivicOptimize]: "Process Automation",
    [ProductType.CivicPlusIntegrationHub]: "CivicPlus Integration Hub",
    [ProductType.CivicPlusOrganizations]: "CivicPlus Organizations",
    [ProductType.CivicPlusPortal]: "CivicPlus Portal",
    [ProductType.CpPay]: "CivicPlus Pay",
    [ProductType.CivicReady]: "Mass Notification",
    [ProductType.CivicRec]: "Recreation Management",
    [ProductType.HCMS]: "CivicPlus HCMS",
    [ProductType.Monsido]: "Accessibility Compliance",
    [ProductType.MunicodeMeetings]: "Meetings Essential",
    [ProductType.MunicodeNEXT]: "Online Code Hosting",
    [ProductType.NextRequest]: "Open Records Request",
    [ProductType.Notifications]: "CivicPlus Notifications",
    [ProductType.SeeClickFix]: "311 CRM"
};

export const chatbotOrgs = [
    "kraken",
    "ui-automation",
    "civicplus-internal",
    "cp-emeraldcity",
    "civicplus-prospectdemo"
];
