import React, { useEffect, useState, useRef } from "react";
import ApiService, { ApiError, parseErrorMessage } from "../../../services/apiService";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import Checkbox from "@civicplus/preamble-ui/lib/Checkbox";
import enhanceWithValidation, { requiredValidation, ValidationResult } from "@civicplus/preamble-ui/lib/Validations";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import PhoneNumberInput from "@civicplus/preamble-ui/lib/PhoneNumberInput";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import useGlobalStyles from "../../../shared/globalStyles";
import { isNullOrWhiteSpace } from "@civicplus/preamble-ui/lib/Utilities/StringHelper";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { softMaxLengthValidation } from "../../../shared/customValidation";
import { useAuth } from "../../../providers/AuthProvider";
import {
    emptyPhone,
    isValidPhoneNumberValidation,
    PhoneNumber,
    cleanUpPhoneNumber,
    removeCountryCode
} from "./PhoneNumberUtils";
import { useSnackbar } from "notistack";

const EnhancedTextInput = enhanceWithValidation(TextInput);
const EnhancedPhoneNumberInput = enhanceWithValidation(PhoneNumberInput);

const useStyles = makeStyles((theme) => ({
    cardContentNoTopBottomPadding: {
        paddingTop: "0px",
        paddingBottom: "0px",
        "& .prmbl-formBuilder-fieldWrapper": {
            margin: `0`
        },
        "& .prmbl-buttonGroup": {
            marginTop: theme.spacing(1.5)
        }
    },
    phoneNumberListing: {
        margin: theme.spacing(2, 0)
    },
    phoneElement: {
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
            borderColor: theme.palette.primary.main
        }
    },
    bottomButtonSpcing: {
        marginBottom: "0.5rem"
    }
}));

interface PhoneNumberFormProps {
    finishedAddPhoneNumberAction: (isLoading: boolean, newNumber?: PhoneNumber) => void;
    existingNumberCount: number;
}

const PhoneNumberForm: React.FC<PhoneNumberFormProps> = (props) => {
    const { finishedAddPhoneNumberAction, existingNumberCount } = props;

    const classes = useStyles();
    const globalClasses = useGlobalStyles();
    const auth = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>(emptyPhone);
    const [enableSave, setEnableSave] = useState(false);
    const phoneLabelRef = useRef<{ validate: () => ValidationResult }>();
    const phoneRef = useRef<{ validate: () => ValidationResult }>();

    useEffect(() => {
        if (existingNumberCount === 0) {
            setPhoneNumber({
                ...emptyPhone,
                isDefaultNumber: true
            });
        }
    }, []);

    useEffect(() => {
        setPhoneNumber((prevPhone) => ({ ...prevPhone, label: `Phone Number ${existingNumberCount + 1}` }));
    }, [existingNumberCount, props]);

    const onIsAllowSmsChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setPhoneNumber((prevPhone) => ({ ...prevPhone, allowSms: checked }));

        evaluateEnableSave(phoneNumber.number, checked, undefined);
    };

    const onIsAllowVoiceChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setPhoneNumber((prevPhone) => ({ ...prevPhone, allowVoice: checked }));

        evaluateEnableSave(phoneNumber.number, undefined, checked);
    };

    const onIsDefaultChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setPhoneNumber((prevPhone) => ({ ...prevPhone, isDefaultNumber: checked }));
    };

    const evaluateEnableSave = (
        phoneValue: string | undefined,
        allowSmsValue: boolean | undefined,
        allowVoiceValue: boolean | undefined
    ) => {
        const phone = phoneValue ?? phoneNumber.number;
        const allowSms = allowSmsValue !== undefined ? allowSmsValue : phoneNumber.allowSms;
        const allowVoice = allowVoiceValue !== undefined ? allowVoiceValue : phoneNumber.allowVoice;

        if (phone && !isNullOrWhiteSpace(phone) && (allowSms || allowVoice)) {
            setEnableSave(true);
        } else {
            setEnableSave(false);
        }
    };

    const onPhoneNumberLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e && e.currentTarget) {
            setPhoneNumber((prevPhone) => ({ ...prevPhone, label: e.currentTarget?.value }));
        }
    };

    const onPhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e && e.currentTarget) {
            const targetValue = e.currentTarget?.value;

            setPhoneNumber((prevPhone) => ({ ...prevPhone, number: cleanUpPhoneNumber(targetValue) }));
            evaluateEnableSave(targetValue, undefined, undefined);
        }
    };

    const onSavePhone = async () => {
        if (!phoneNumber.number) {
            enqueueSnackbar("Phone Number and label must be provided to continue.", {
                variant: "error",
                persist: true
            });
            return;
        }

        const validationResults: ValidationResult[] = await Promise.resolve([
            phoneRef.current!.validate(),
            phoneLabelRef.current!.validate()
        ]);

        if (validationResults.some((x) => x.error)) {
            enqueueSnackbar("All fields must be valid to continue.", {
                variant: "error",
                persist: true
            });
            return;
        }

        finishedAddPhoneNumberAction(true, undefined);

        await ApiService.post({
            url: "user/account/phoneNumber",
            authUser: auth.user,
            requestInit: {
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    number: removeCountryCode(phoneNumber.number),
                    allowSms: phoneNumber.allowSms,
                    allowVoice: phoneNumber.allowVoice,
                    countryCode: phoneNumber.countryCode,
                    label: phoneNumber.label,
                    isDefaultNumber: phoneNumber.isDefaultNumber
                })
            }
        })
            .then((response) => {
                enqueueSnackbar("Phone number saved successfully!", {
                    variant: "success"
                });

                if (response) {
                    setPhoneNumber((prevPhone) => ({ ...prevPhone, id: response.id }));
                    finishedAddPhoneNumberAction(false, response);
                }

                return true;
            })
            .catch((error) => {
                const message = parseErrorMessage(error as ApiError, "Failed to save a new phone number.");
                enqueueSnackbar(message, {
                    variant: "error",
                    persist: true
                });
                finishedAddPhoneNumberAction(false, undefined);
            });
    };

    return (
        <>
            <Grid container={true} style={{ marginTop: 15 }}>
                <Grid xs={12} md="auto">
                    <Checkbox
                        id="allowSms"
                        label="Supports SMS / text messages"
                        onChange={onIsAllowSmsChange}
                        checked={phoneNumber.allowSms}
                        size="small"
                    />
                </Grid>

                <Grid xs={12} md="auto">
                    <Checkbox
                        id="allowVoice"
                        label="Supports voice calls"
                        onChange={onIsAllowVoiceChange}
                        checked={phoneNumber?.allowVoice}
                        size="small"
                    />
                </Grid>

                <Grid xs={12}>
                    <EnhancedTextInput
                        ref={phoneLabelRef}
                        id="phoneNumberLabel"
                        label="Phone Number Label"
                        value={phoneNumber.label}
                        onChange={onPhoneNumberLabelChange}
                        fullWidth={true}
                        softMaxLength={30}
                        validations={[softMaxLengthValidation]}
                    />
                </Grid>

                <Grid xs={12}>
                    <EnhancedPhoneNumberInput
                        ref={phoneRef}
                        id="phoneNumber"
                        label="Phone Number"
                        value={phoneNumber.number}
                        onChange={onPhoneNumberChange}
                        fullWidth={true}
                        required={true}
                        maxLength={10}
                        autoComplete="tel-local"
                        placeholder="+1 (000) 000-0000"
                        maskTemplate={[
                            "+",
                            "1",
                            " ",
                            "(",
                            /[1-9]/,
                            /\d/,
                            /\d/,
                            ")",
                            " ",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/
                        ]}
                        regExpPattern={"^\\+1\\s\\([1-9]\\d{2}\\)\\s\\d{3}-\\d{4}"}
                        validations={[requiredValidation, isValidPhoneNumberValidation]}
                    />
                </Grid>

                <Grid md={6} xs={12} className={globalClasses.alignedCenter} style={{ marginTop: 10 }}>
                    <Checkbox
                        id="isDefaultPhoneNumber"
                        label="This is my Default Phone Number"
                        size="small"
                        color="primary"
                        onChange={onIsDefaultChange}
                        disabled={existingNumberCount === 0}
                        checked={
                            existingNumberCount === 0 && phoneNumber.id === "" ? true : phoneNumber.isDefaultNumber
                        }
                    />
                </Grid>

                <Grid md={6} xs={12} className={globalClasses.justifiedFlexEnd} style={{ marginTop: 10 }}>
                    <ButtonGroup id="saveAndCancel" className={classes.bottomButtonSpcing}>
                        <Button color="primary" disabled={!enableSave} onClick={() => onSavePhone()}>
                            Save Changes
                        </Button>

                        <Button
                            data-testid="cancel-add-phone"
                            onClick={() => {
                                setPhoneNumber(emptyPhone);
                                return finishedAddPhoneNumberAction(false, undefined);
                            }}
                        >
                            Cancel
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </>
    );
};

export default PhoneNumberForm;
